

































































































































































































































































import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import { db, storage } from '@/main';
import algoliasearch from 'algoliasearch';
import constants from '../constants/constants';
import {
  ContractType,
  CurrencyType,
  PerkType,
  Position,
  WorkConditionsType
} from '@/models/position.model';
import { Practice } from '@/models/practice.model';
import router from '@/router';

export default Vue.extend({
  name: 'CandidatePositions',
  components: {
    SalaryValue: () => import('@/components/SalaryValue.vue')
  },
  data() {
    return {
      router: router,
      vfOptions: {
        autoplay: true
      },
      vfTransitions: ['fade', 'cube', 'book', 'wave'],
      benched: 5,
      selectedPosition: '',
      snackbar: false,
      snackbarColor: '',
      snackbarText: '',
      showEditModalValue: false,
      showDetailsModalValue: false,
      footerProps: { 'items-per-page-options': [20, 50, 100, 200, 500, 1000] },
      headers: [
        { value: 'title', text: 'Title', sortable: true },
        { value: 'contractType', text: 'Contract Type', sortable: true },
        { value: 'minSalary', text: 'Min Salary', sortable: true },
        { value: 'maxSalary', text: 'Max Salary', sortable: true },
        {
          value: 'conditionsMatch',
          text: 'Conditions Match',
          sortable: false,
          align: 'center'
        },
        {
          value: 'salaryMatch',
          text: 'Salary Match',
          sortable: false,
          align: 'center'
        },
        {
          value: 'skillsMatch',
          text: 'Skills Match',
          sortable: false,
          align: 'center'
        },
        { value: 'city', text: 'City', sortable: false },
        { value: 'practice', text: 'Practice', sortable: false },
        { value: 'distance', text: 'Travel Distance', sortable: true },
        { value: 'duration', text: 'Travel Duration', sortable: true }
      ],
      rules: {
        required: (value: string) => !!value || 'Required.'
      },
      options: {} as any,
      page: 1,
      totalRows: 1,
      perPage: { value: 50, text: '50 per page' },
      position: {} as Position,
      positions: [] as Position[],
      client: algoliasearch(
        constants.ALGOLIA.APP_ID,
        constants.ALGOLIA.SEARCH_KEY
      ),
      topVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      bottomVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      isBusy: false,
      headingText: 'Positions',
      previousPageNumber: 1,
      dataListener: (null as null | {}) as () => void,
      practices: {},
      practiceDistances: {},
      practiceDurations: {},
      practiceLogoUrls: {},
      practiceImageUrls: {},
      practiceImageUrlsIndexes: {},
      topPerks: {},
      annualySalaryContracts: ['Full-time', 'Part-time'],
      experience:
        new Date().getFullYear() -
        Number(this.$store.state.currentUser.yearOfAccreditation),
      candidate: this.$store.state.currentUser,
      workConditionTypes: Object.values(WorkConditionsType) as string[],
      contractTypes: Object.values(ContractType) as string[],
      perkTypes: Object.values(PerkType) as string[],
      currencyTypes: Object.values(CurrencyType),
      selectedConditions: this.$store.state.currentUser.workConditionTypes,
      selectedContractTypes: this.$store.state.currentUser
        .preferredContractTypes,
      selectedPerkTypes: this.$store.state.currentUser.preferredPerkTypes,
      minDailySalary: this.$store.state.currentUser.minDailySalary,
      minAnnualSalary: this.$store.state.currentUser.minAnnualSalary,
      currency: this.$store.state.currentUser.currency,
      minWeeksAnnualLeave: this.$store.state.currentUser.minWeeksAnnualLeave,
      index: null
    };
  },
  methods: {
    updateShowDetailsModalValue(value: boolean) {
      this.showDetailsModalValue = value;
    },
    initData() {
      this.getTotalPositionCount();
      this.getData();
    },
    getTotalPositionCount() {
      // const filter = `practiceUid:${this.$store.state.currentUser.practiceUids[0]}`;
      this.algoliaClient
        .search('', {
          // filters: filter
        })
        .then(({ nbHits }: any) => {
          this.totalRows = nbHits > 0 ? nbHits : 1;
        })
        .catch((err: any) => {
          console.log(err);
          return null;
        });
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.isBusy = true;
      let query: any = this.mainQuery;
      let order = 'asc' as 'asc' | 'desc';
      let sort = 'internalReference';
      if (sortBy && sortBy.length > 0) {
        sort = sortBy[0];
      }
      if (sortDesc !== undefined && sortDesc.length > 0) {
        order = sortDesc[0] ? 'desc' : 'asc';
      }
      query = query.orderBy(sort, order);
      const pageDiff = (page ?? 1) - this.previousPageNumber;
      this.previousPageNumber = page ?? 1;
      switch (pageDiff) {
        case 0:
          query = query.limit(itemsPerPage ?? this.perPage.value);
          break;
        case 1:
          query = query
            .startAfter(this.bottomVisible)
            .limit(itemsPerPage ?? this.perPage.value);
          break;
        case -1:
          query = query
            .endBefore(this.topVisible)
            .limitToLast(itemsPerPage ?? this.perPage.value);
          break;
        default:
          query = query.limit(itemsPerPage ?? this.perPage.value);
      }
      if (this.dataListener) {
        this.dataListener();
      }
      this.dataListener = query.onSnapshot(
        async (snapshot: firebase.firestore.QuerySnapshot) => {
          this.isBusy = false;
          if (snapshot.docs.length > 0) {
            this.positions = snapshot.docs.map(
              value => value.data() as Position
            );
            snapshot.docs.forEach(async value => {
              const pos = value.data() as Position;
              const practice = await db
                .collection('practices')
                .doc(pos.practiceUid)
                .get()
                .then(doc => doc.data());
              const result = await this.getDistance(practice?.googlePlaceId);
              console.log('result: ', result.data.distance.value);
              pos.distanceToPractice = result.data.distance.value;
              const index = this.positions.findIndex(
                posit => posit.uid === pos.uid
              );
              Vue.set(this.positions, index, pos);
              this.positions.sort((a, b) =>
                (a.distanceToPractice ?? 0) > (b.distanceToPractice ?? 0)
                  ? 1
                  : (b.distanceToPractice ?? 0) > (a.distanceToPractice ?? 0)
                  ? -1
                  : 0
              );
            });
            this.positions = this.positions.filter((position: Position) => {
              return (
                (position.workConditionTypes?.every(value =>
                  this.selectedConditions.includes(value)
                ) ||
                  !position.workConditionTypes) &&
                this.selectedPerkTypes.every(value =>
                  position.perkTypes?.includes(value)
                ) &&
                this.$store.state.currentUser.vetAnimalTypes.some(value =>
                  position.vetAnimalTypes?.includes(value)
                ) &&
                this.selectedContractTypes.includes(
                  position.contractType ?? ''
                ) &&
                (this.annualySalaryContracts.includes(
                  position.contractType ?? ''
                )
                  ? (this.convertCurrencyToDollarBase(
                      position.currency as CurrencyType,
                      Number(position.maxAnnualSalary)
                    ) ?? 0) >=
                    this.convertCurrencyToDollarBase(
                      this.currency as CurrencyType,
                      Number(this.minAnnualSalary)
                    )
                  : (this.convertCurrencyToDollarBase(
                      position.currency as CurrencyType,
                      Number(position.maxDailySalary)
                    ) ?? 0) >=
                    this.convertCurrencyToDollarBase(
                      this.currency as CurrencyType,
                      Number(this.minDailySalary)
                    )) &&
                (this.annualySalaryContracts.includes(
                  position.contractType ?? ''
                )
                  ? (this.convertCurrencyToDollarBase(
                      position.currency as CurrencyType,
                      Number(position.maxAnnualSalary)
                    ) ?? 0) >=
                    this.convertCurrencyToDollarBase(
                      this.currency as CurrencyType,
                      Number(this.minAnnualSalary)
                    )
                  : (this.convertCurrencyToDollarBase(
                      position.currency as CurrencyType,
                      Number(position.maxDailySalary)
                    ) ?? 0) >=
                    this.convertCurrencyToDollarBase(
                      this.currency as CurrencyType,
                      Number(this.minDailySalary)
                    ))
              );
            });

            this.positions.forEach(position => {
              if (position.perkTypes && position.perkTypes.length > 0) {
                let topPerks = position.perkTypes;
                topPerks?.unshift(
                  topPerks.splice(
                    topPerks.findIndex(value => value === PerkType.perk4),
                    1
                  )[0]
                );
                topPerks?.unshift(
                  topPerks.splice(
                    topPerks.findIndex(value => value === PerkType.perk3),
                    1
                  )[0]
                );
                topPerks?.unshift(
                  topPerks.splice(
                    topPerks.findIndex(value => value === PerkType.perk2),
                    1
                  )[0]
                );
                topPerks?.unshift(
                  topPerks.splice(
                    topPerks.findIndex(value => value === PerkType.perk1),
                    1
                  )[0]
                );
                topPerks = topPerks?.slice(0, 4);
                Vue.set(this.topPerks, position.uid, topPerks);
              }
            });
            this.getPractices();
            this.topVisible = snapshot.docs[0];
            this.bottomVisible = snapshot.docs[snapshot.docs.length - 1];
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );
    },
    getPractices() {
      this.practiceDistances = {};
      this.practiceDurations = {};
      const practiceUids = this.positions.map(
        (value: Position) => value.practiceUid
      );
      practiceUids.forEach((uid: string) => {
        db.collection('practices')
          .doc(uid)
          .get()
          .then(
            async doc => {
              const practice = doc.data() as Practice;
              Vue.set(this.practices, practice.uid, practice);

              if (practice.logoImageRef) {
                Vue.set(
                  this.practiceLogoUrls,
                  practice.uid,
                  await storage.ref(practice.logoImageRef).getDownloadURL()
                );
              }

              if (practice.insidePracticeImageRefs) {
                Vue.set(
                  this.practiceImageUrls,
                  practice.uid,
                  await Promise.all(
                    practice.insidePracticeImageRefs?.map(async ref =>
                      storage.ref(ref).getDownloadURL()
                    )
                  )
                );
              }
              Vue.set(this.practiceImageUrlsIndexes, practice.uid, 0);
              const result = await this.getDistance(practice.googlePlaceId);
              Vue.set(
                this.practiceDistances,
                practice.uid,
                result.data.distance.text
              );
              Vue.set(
                this.practiceDurations,
                practice.uid,
                result.data.duration.text
              );
            },
            (error: Error) => {
              console.error(error);
            }
          );
      });
    },
    getDistance(practicePlaceId: string): Promise<any> {
      return firebase
        .functions()
        .httpsCallable('getDistanceBetweenPlaces')({
          origin: this.$store.state.currentUser.googlePlaceId,
          destination: practicePlaceId
        })
        .then(result => {
          return result;
        })
        .catch(error => {
          console.error(error);
          return error;
        });
    },
    updateUser() {
      const user = this.$store.state.currentUser;
      db.collection('users')
        .doc(user.uid)
        .set(
          {
            workConditionTypes: this.selectedConditions ?? [],
            preferredContractTypes: this.selectedContractTypes ?? [],
            preferredPerkTypes: this.selectedPerkTypes ?? [],
            minDailySalary: this.minDailySalary ?? 0,
            minAnnualSalary: this.minAnnualSalary ?? 0,
            currency: this.currency ?? 'usd',
            minWeeksAnnualLeave: this.minWeeksAnnualLeave ?? 0
          },
          { merge: true }
        );
    },
    selectedRow(item: Position) {
      this.position = item;
      this.showDetailsModalValue = true;
    },
    showEditModal() {
      this.showEditModalValue = true;
    },
    applied() {
      this.showSnackbar(
        'Successfuly applied. The practice will be in touch if you are successful',
        'success'
      );
    },
    convertCurrencyToDollarBase(currency: CurrencyType, value: number): number {
      switch (currency) {
        case CurrencyType.usd:
          return value;
        case CurrencyType.gbp:
          return value / 0.72;
        case CurrencyType.eur:
          return value / 0.84;
        case CurrencyType.zar:
          return value / 15.32;
        case CurrencyType.aud:
          return value / 1.36;
        case CurrencyType.nzd:
          return value / 1.43;
        default:
          return value;
      }
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  watch: {
    selectedPosition() {
      if (this.selectedPosition) {
        this.position = this.positions.find(
          value => value.uid === this.selectedPosition
        ) as Position;
        this.selectedPosition = '';
        this.showDetailsModalValue = true;
      }
    },
    selectedConditions() {
      this.updateUser();
      this.initData();
    },
    selectedContractTypes() {
      this.updateUser();
      this.initData();
    },
    selectedPerkTypes() {
      this.updateUser();
      this.initData();
    },
    minDailySalary() {
      this.updateUser();
      this.initData();
    },
    minAnnualSalary() {
      this.updateUser();
      this.initData();
    },
    currency() {
      this.updateUser();
      this.initData();
    },
    minWeeksAnnualLeave() {
      this.updateUser();
      this.initData();
    },
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  created() {
    this.initData();
  },
  beforeDestroy() {
    if (this.dataListener) {
      this.dataListener();
    }
  },
  computed: {
    algoliaClient(): any {
      return this.client.initIndex(constants.ALGOLIA.INDEXES.POSITIONS);
    },
    mainQuery() {
      const queryRef = db
        .collection('positions')
        .where('isApproved', '==', true);
      return queryRef;
    }
  }
});
